import React, { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { FetchProfileData } from "./FetchProfileData";

const SingleView = () => {
  const { id } = useParams();
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const item = FetchProfileData(id);

  return (
    <div ref={containerRef}>
      {item}
    </div>
  );
};

export default SingleView;