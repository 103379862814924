import React from "react";
import data from "./data.json"; // Import JSON data
import Photo from "./Photo";
import BSData from "./brother-sister.json";
import BrotherSister from "./BrotherSister.js";

const FetchProfileData = (id) => {
  const item = data.find((item) => item["Entry Id"] === parseInt(id));

  const handleBackClick = () => {
    // Go back one step in the browser history
    window.history.back();
  };
  // 1997-11-22 to 22-11-1997
  const birthtime = item["જન્મ સમય( ૧ થી ૨૪ કલાક)"];

  const oldbirthdate = item["જન્મ તારીખ"];
  const birthdate = oldbirthdate
    .split("-")
    .reverse()
    .join("-");
  
  

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="md:flex">
        <button
          className="bg-orange-500 p-4 pl-5 pr-5 rounded font-bold text-white"
          onClick={handleBackClick}
        >
          Back
        </button>
        <div className="md:flex-shrink-0 p-5 max-w-64">
          <Photo item={item} />
        </div>
        <div className="p-6 flex flex-col">
          <div className="text-2xl font-bold">{`${item["પૂરું નામ (First)"]} ${item["પૂરું નામ (Middle)"]} ${item["પૂરું નામ (Last)"]}`}</div>
          {item["ઉમેદવાર નો વ્યવસાય"] === "" ? (
            () => {}
          ) : (
            <div className="bg-orange-200 p-4 rounded-lg mb-4 mt-2">
              <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                વ્યવસાય :{" "}
              </span>
              <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                {item["ઉમેદવાર નો વ્યવસાય"]}
              </span>
            </div>
          )}

          <div className="flex w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
              {item["ફોન"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      ફોન:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      <a href={`tel:${item["ફોન"]}`}>{`${item["ફોન"]}`}</a>
                    </span>
                  </div>
                </div>
              )}

              {item["WhatApp નંબર"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      WhatApp નંબર:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      <a href={`tel:${item["WhatApp નંબર"]}`}>{`${item["ફોન"]}`}</a>
                    </span>
                  </div>
                </div>
              )}

              {item["ઈ-મેલ"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      ઈ-મેલ:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      <a
                        href={`mailto:${item["ઈ-મેલ"]}`}
                      >{`${item["ઈ-મેલ"]}`}</a>
                    </span>
                  </div>
                </div>
              )}

              {item["સંપર્ક સરનામું (સરનામું)"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      સરનામું:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      {`${item["સંપર્ક સરનામું (સરનામું)"]}`}
                    </span>
                  </div>
                </div>
              )}

              {item["સંપર્ક સરનામું (શહેર)"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      શહેર:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      {`${item["સંપર્ક સરનામું (શહેર)"]}`}
                    </span>
                  </div>
                </div>
              )}

              {item["સંપર્ક સરનામું (રાજ્ય)"] === "" ? (
                () => {}
              ) : (
                <div className="bg-orange-200 p-4 rounded-lg">
                  <div className="flex items-center">
                    <span className="text-sm sm:text-sm px-1 font-bold pr-2">
                      રાજ્ય:
                    </span>
                    <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
                      {`${item["સંપર્ક સરનામું (રાજ્ય)"]}`}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border border-orange-400 p-5">
        <div className="bg-orange-900 p-4 text-xl text-white rounded-lg mt-2 flex content-center items-center font-bold border-2 border-orange-500">
          વ્યક્તિગત માહિતી
        </div>

        {birthdate === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              જન્મ તારીખ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {birthdate}
            </span>
          </div>
        )}

        {/* જન્મ સમય(૨૪ કલાકમાં) */}

        {birthtime === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              જન્મ સમય(૨૪ કલાકમાં) :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {birthtime}
            </span>
          </div>
        )}

        {/* "જન્મ સ્થળ"] */}

        {item["જન્મ સ્થળ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              જન્મ સ્થળ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["જન્મ સ્થળ"]}
            </span>
          </div>
        )}

        {/* "વતન"] */}

        {item["વતન"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              વતન :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["વતન"]}
            </span>
          </div>
        )}

        {item["અભ્યાસ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              અભ્યાસ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["અભ્યાસ"]}
            </span>
          </div>
        )}

        {/* "વેવાહિક દરજો" */}

        {item["વેવાહિક દરજો"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              વેવાહિક દરજો :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["વેવાહિક દરજો"]}
            </span>
          </div>
        )}

        {/* "માસિક આવક" */}

        {item["માસિક આવક"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              માસિક આવક :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["માસિક આવક"]}
            </span>
          </div>
        )}

        {/* "વ્યક્તિગત માહિતી ૨" */}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border border-orange-400 p-5">
        <div className="bg-orange-900 p-4 text-xl text-white rounded-lg mt-2 flex content-center items-center font-bold border-2 border-orange-500">
          વ્યક્તિગત માહિતી 2
        </div>

        {/* ગોત્ર */}

        {item["ગોત્ર"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              ગોત્ર :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["ગોત્ર"]}
            </span>
          </div>
        )}

        {/* નાડી */}

        {item["નાડી"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              નાડી :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["નાડી"]}
            </span>
          </div>
        )}

        {/* "પેટા જ્ઞાતિ" */}

        {item["પેટા જ્ઞાતિ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              પેટા જ્ઞાતિ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["પેટા જ્ઞાતિ"]}
            </span>
          </div>
        )}

        {/* "શારીરિક ક્ષતિ ની નોધ" */}

        {item["શારીરિક ક્ષતિ ની નોધ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              શારીરિક ક્ષતિ ની નોધ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["શારીરિક ક્ષતિ ની નોધ"]}
            </span>
          </div>
        )}

        {/* "વજન" */}

        {item["વજન"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              વજન :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["વજન"]}
            </span>
          </div>
        )}

        {/* "ઊચાઇ" */}

        {item["ઊચાઇ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              ઊચાઇ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["ઊચાઇ"]}
            </span>
          </div>
        )}

        {/* "ચામડી નો રંગ" */}

        {item["ચામડી નો રંગ"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              ચામડી નો રંગ :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["ચામડી નો રંગ"]}
            </span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border border-orange-400 p-5">
        <div className="bg-orange-900 p-4 text-xl text-white rounded-lg mt-2 flex content-center items-center font-bold border-2 border-orange-500">
          પરિવાર ની માહિતી
        </div>

        {/* "પિતાનો વ્યવસાય" */}

        {item["પિતાનો વ્યવસાય"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              પિતાનો વ્યવસાય :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["પિતાનો વ્યવસાય"]}
            </span>
          </div>
        )}

        {/* "પિતા ની માસિક આવક" */}

        {item["પિતા ની માસિક આવક"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              પિતા ની માસિક આવક :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["પિતા ની માસિક આવક"]}
            </span>
          </div>
        )}

        {/* "માતા નો વ્યવસાય" */}

        {item["માતા નો વ્યવસાય"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              માતા નો વ્યવસાય :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["માતા નો વ્યવસાય"]}
            </span>
          </div>
        )}

        {/* "માતા ની માસિક આવક" */}

        {item["માતા ની માસિક આવક"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              માતા ની માસિક આવક :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["માતા ની માસિક આવક"]}
            </span>
          </div>
        )}

        {/* "ભાઈ / બહેન છે?" */}

        {item["ભાઈ / બહેન છે?"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              ભાઈ / બહેન છે? :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["ભાઈ / બહેન છે?"]}
            </span>
          </div>
        )}

        {/* "ભાઈ બહેન ની સંખ્યા" */}

        {item["ભાઈ / બહેન છે?"] === "ના" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <BrotherSister id={item["ભાઈ / બહેન ની વિગત"]} data={BSData} />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border border-orange-400 p-5">
        <div className="bg-orange-900 p-4 text-xl text-white rounded-lg mt-2 flex content-center items-center font-bold border-2 border-orange-500">
          અન્ય માહિતી{" "}
        </div>

        {/* મંગળ છે */}

        {item["મંગળ છે?"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              મંગળ છે? :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["મંગળ છે?"]}
            </span>
          </div>
        )}

        {/* ચશ્મા છે? */}

        {item["ચશ્મા છે?"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              ચશ્મા છે? :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["ચશ્મા છે?"]}
            </span>
          </div>
        )}

        {/* શનિ છે? */}

        {item["શનિ છે?"] === "" ? (
          () => {}
        ) : (
          <div className="bg-orange-200 p-4 rounded-lg">
            <span className="text-xs sm:text-sm px-4 font-bold pr-2">
              શનિ છે? :
            </span>
            <span className="text-sm sm:text-sm px-1 font-bold text-orange-600">
              {item["શનિ છે?"]}
            </span>
          </div>
        )}

      </div>
    </div>
  );
};

export { FetchProfileData };
