// src/App.js
import React, { useState } from "react";
import data from "./data.json";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
import SearchComponent from "./SearchComponent.js";
import ProfileView from "./Profileview.js";
import SingleView from "./SingleView.js";

function App() {
  const [searchQuery, setSearchQuery] = useState("");
  const [candidate, setCandidate] = useState("બન્ને");
  const [maritalStatus, setMaritalStatus] = useState("બન્ને");

  const handleSearch = (query, candidateValue, maritalStatusValue) => {
    setSearchQuery(query);
    setCandidate(candidateValue);
    setMaritalStatus(maritalStatusValue);
  };

  const MainSearch = (data, candidate, maritalStatus) => {
    return data.filter((item) => {
      const nameMatch =
        (item["પૂરું નામ (First)"]?.toLowerCase() ?? "").includes(
          searchQuery.toLowerCase()
        ) ||
        (item["પૂરું નામ (Middle)"]?.toLowerCase() ?? "").includes(
          searchQuery.toLowerCase()
        ) ||
        (item["પૂરું નામ (Last)"]?.toLowerCase() ?? "").includes(
          searchQuery.toLowerCase()
        ) ||
        (item["Entry Id"]?.toString().toLowerCase() ?? "").includes(
          searchQuery.toLowerCase()
        );

      const candidateMatch =
        candidate === "બન્ને" ||
        (candidate === "યુવક" && item["ઉમેદવાર"] === "યુવક") ||
        (candidate === "યુવતી" && item["ઉમેદવાર"] === "યુવતી");

      const maritalStatusMatch =
        maritalStatus === "બન્ને" ||
        (maritalStatus === "છૂટાછેડા" && item["વેવાહિક દરજો"] === "છૂટાછેડા") ||
        (maritalStatus === "અપરણિત" && item["વેવાહિક દરજો"] === "અપરણિત");

      return nameMatch && candidateMatch && maritalStatusMatch;
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SearchComponent onSearch={handleSearch} />
                  <Outlet />
                </>
              }
            >
              <Route
                index
                element={
                  <ProfileView
                    data={MainSearch(data, candidate, maritalStatus)}
                  />
                }
              />
            </Route>
            <Route path="/profile/:id" element={<SingleView data={data} />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
