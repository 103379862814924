import Boy from "./Boy.js";
import Girl from "./Girl.js";



const Photo = ({ item }) => { 

  return (
    <div>
      {item["ઉમેદવાર"] === "યુવક" ? (
        <Boy item={item} />
      ) : (
        <Girl item = {item}/>
      )}

    </div>
  );
};

export default Photo;