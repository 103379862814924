// src/SearchComponent.js
import React, { useState } from 'react';
import './SearchComponent.css'; // Import CSS file

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [candidate, setCandidate] = useState('બન્ને');
  const [maritalStatus, setMaritalStatus] = useState('બન્ને');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value, candidate, maritalStatus);
  };

  const handleCandidateChange = (event) => {
    setCandidate(event.target.value);
    onSearch(searchTerm, event.target.value, maritalStatus);
  };

  const handleMaritalStatusChange = (event) => {
    setMaritalStatus(event.target.value);
    onSearch(searchTerm, candidate, event.target.value);
  };

  return (
    <div className="search-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Name, Surname, Entry Id..."
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>
      <div className="filters">
        <div className="filter">
          <label>ઉમેદવાર:</label>
          <select value={candidate} onChange={handleCandidateChange}>
            <option value="બન્ને">યુવક/યુવતી</option>
            <option value="યુવક">યુવક</option>
            <option value="યુવતી">યુવતી</option>
          </select>
        </div>
        <div className="filter">
          <label>વેવાહિક દરજો:</label>
          <select value={maritalStatus} onChange={handleMaritalStatusChange}>
            <option value="બન્ને">તમામ</option>
            <option value="છૂટાછેડા">છૂટાછેડા</option>
            <option value="અપરણિત">અપરણિત</option>
          </select>
        </div>

        <div><a href="https://brahmsamaj.ltree.in/" className='flex p-2 bg-orange-500 pr-5 pl-5 hover:bg-orange-700 font-bold text-white border' target="_blank" rel="noopener noreferrer">
        Add New Profile
      </a></div>
      </div>
    </div>
  );
};

export default SearchComponent;