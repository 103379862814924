import extractFileName from "./extractFileName.js";

const Boy = ({ item }) => {

  if (item["Entry Id"] === 898) {
    console.log(extractFileName(item["ઉમેદવાર નો ફોટો"].split("|:||:||:||:|")[0]));
  }


  return (
    <div>
      {(item["ઉમેદવાર નો ફોટો"] === "") ||
      item["ઉમેદવાર નો ફોટો"] === undefined ||
      item["ઉમેદવાર નો ફોટો"] === null ? (
        <img
          src="./profile-picture/boy.jpg"
          className="w-full h-full object-contain aspect-auto"
          alt=""
        />
      ) : (
        <img
          src={`./profile-picture/${extractFileName(
            item["ઉમેદવાર નો ફોટો"].split("|:||:||:||:|")[0]
          )}.jpeg`}
          className="w-full h-full object-contain aspect-auto"
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "./profile-picture/boy.jpg";
          }}
        />
      )
      }
    </div>
  );
};

export default Boy;
