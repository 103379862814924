import React, { useState, useEffect } from "react";
import Photo from "./Photo";
import { Link } from 'react-router-dom';


const ProfileView = ({ data }) => {
  const [item, setitem] = useState(null);

  useEffect(() => {
    setitem(data);
  }, [data]);

  return (
    <div>
      {item && (
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 sm:grid-cols-4 gap-4 text-xs">
          {item.map((item, index) =>
            item["પૂરું નામ (First)"] !== "" &&
            item["પૂરું નામ (First)"] !== undefined &&
            item["પૂરું નામ (First)"] !== null ? (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <div className="p-2">
                  <div className="md:flex-shrink-0 rounded overflow-hidden">
                    <Photo item={item} />
                  </div>
                  <div className="pt-2">
                    <div className="text-xs text-orange-500 font-bold pb-1 flex flex-row justify-between items-center">
                        {item["Entry Id"]}{" "}                        
                        <span className="text-orange-400 text-[10px]">{item["વેવાહિક દરજો"]}</span>
                        <span className="text-white font-bold text-[10px] p-1 bg-orange-800 rounded">{item["સંપર્ક સરનામું (શહેર)"]}</span>{" "}
                    </div>
                    
                       <Link className="block mt-1 text-xs leading-tight font-semibold text-gray-900 hover:underline" to={`/profile/${item["Entry Id"]}`}> {`${item["પૂરું નામ (First)"]} ${item["પૂરું નામ (Middle)"]} ${item["પૂરું નામ (Last)"]}`}</Link>
                    <div className="mt-2 text-gray-600">
                      
                    </div>
                    <div className="mt-4">
                        <Link className="bg-orange-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs" to={`/profile/${item["Entry Id"]}`}>Full Profile</Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileView;