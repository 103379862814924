
import React, { useState, useEffect } from "react";


const BrotherSister = ({ id, data }) => { 
  const [filteredData, setFilteredData] = useState([]);


 useEffect(() => {
  if (data) {
    let ids;
    if (id) {
      ids = id.toString().split(',').map((idStr) => Number(idStr.trim()));
    } else {
      ids = [];
    }

    // Filter `data` based on `ids`
    const filtered = data.filter((item) => ids.includes(item["Entry Id"]));
    setFilteredData(filtered);
  } else if (!id) {
    // If `id` is not provided or data is not available, set filtered data to an empty array or handle accordingly
    setFilteredData([]);
  }
}, [id, data]);


   

  // useEffect(() => {
  //   if (id && data) {
  //     const filtered = data.filter((item) => item["Entry Id"] === id);
  //     setFilteredData(filtered);
  //   }
  // },[id, data]);

  

  return (
    <div>
      <div className="flex-grow border-t border-dashed border-orange-300 mt-2 mb-2"></div>
      <strong className="text-xs sm:text-sm px-4 font-bold pr-2">ભાઈ / બહેન ની વિગત : </strong>
      <ul>
        {filteredData.map((item) => (
          <li key={item["Entry Id"]}>
            <span className="text-xs sm:text-sm px-4 font-bold text-orange-600">{item["ભાઈ/બહેન"]}, {item["પરણિત/અપરણિત"]}, {item["વ્યવસાય ની વિગત"]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BrotherSister;