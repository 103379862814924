import extractFileName from "./extractFileName.js"; 

const Girl = ({item}) => {
  return (
    <div>
      {
        item["ઉમેદવાર નો ફોટો"] === "" ? (
        <img
          src="./profile-picture/girl.jpg"
          alt=""
          className="w-full h-full object-contain aspect-auto"
        />
      ) : (
        <img
          src={`./profile-picture/${extractFileName(
            item["ઉમેદવાર નો ફોટો"].split("|:||:||:||:|")[0]
          )}.jpeg`}
          alt=""
          className="w-full h-full object-contain aspect-auto"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "./profile-picture/girl.jpg";
          }}
        ></img>
      )
      }
    </div>
  );
}

export default Girl;