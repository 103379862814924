 const extractFileName = (urlString) => {
    if (!urlString) {
      return "";
    }

    const urlParts = urlString.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    const fileNameWithExtension = lastPart;
    const fileExtension = fileNameWithExtension.substring(
      fileNameWithExtension.lastIndexOf(".")
    );
    const fileName = fileNameWithExtension.replace(fileExtension, "");

    return fileName;
   
};
  
export default extractFileName;